import React, { Component } from 'react'
import { Modal } from 'antd'
import $ from 'jquery'
import HeadTitle from './HeadTitle'
import UserCIDWidget from './Bricks/UsersCIDWidget'
class MyAccount extends Component {
  state = {
    name: '',
    caller_id: '',
    email: '',
    address: '',
    picture: '',
    role: '',
    imageversion: '',
  }
  ge_avatars = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  componentDidMount() {
    window.resizeHandle()
    //fetch and fill with info
    window.App.blockUI()
    this.setState({ imageversion: this.props.imageversion })
    fetch(this.props.baseUrl + '/index.php/User/Profile', {
      method: 'GET',
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          this.setState({
            name: resp.msg.user_me.full_name,
            caller_id: resp.msg.user_me.caller_id,
            email: resp.msg.user_me.email,
            address: resp.msg.user_me.address,
            picture: resp.msg.user_me.profile_pic,
            role: resp.msg.user_me.role,
          })
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
    ///////////////////////////////
    window.$('[data-toggle=confirmation]').confirmation({
      container: 'body',
      btnOkClass: 'btn btn-sm btn-success',
      btnCancelClass: 'btn btn-sm btn-danger',
    })
  }
  componentWillReceiveProps(nextProps) {
    // This will erase any local state updates!
    // Do not do this.
    this.setState({
      imageversion: nextProps.imageversion,
    })
  }
  updateUserInfo = (form) => {
    form.preventDefault()
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/User/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_editUser').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  handleSubmitUpdateAvi = (e) => {
    e.preventDefault()
    window.App.blockUI()
    const formData = new FormData()
    //avi_action current_avi users_avi
    //for(const name in data) {
    formData.append('action', $('#avi_action').val())
    formData.append('users_avi', document.getElementById('users_avi').files[0])
    formData.append('current_avi', this.state.picture)
    //}
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/User/post_process', {
      credentials: 'include',
      method: 'post',
      body: formData, //new URLSearchParams($("#form_addnewrec").serialize())
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          this.props.onupdateAvi()
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  handleUpdatePassword = (e) => {
    e.preventDefault()
    const mp_new_password = window.$('#mp_new_password').val()
    window.webphone.wp_unregister()
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/User/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_updatePass').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          //lets update the sip password ^_^ and re-register
          if (window.wss_pass !== mp_new_password) {
            Modal.destroyAll()
            Modal.error({
              title: 'Re-Login required',
              content:
                'Your password has been changed. Please Login again with new Password',
              onOk: () => {
                this.props.handleLogout()
              },
            })
          }
          /*window.wss_pass = mp_new_password;
          //window.webphone.wp_unregister();
          //window.webphone.wp_register();
          //
          window.displayAlert("#alert_container", data.msg, "success");
          setTimeout(() => {
            window.webphone.wp_register();
          }, 2500);*/
        } else {
          /*Modal.error({
            title: "Failed",
            content: "Failed to Update Password",
          });*/
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  changeAvatar = (e, av_num) => {
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/User/update_av_generic/' + av_num, {
      credentials: 'include',
      method: 'get',
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          this.props.onupdateAvi()
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Account" subtitle="my" />

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="page-content">
                    <div className="container">
                      <div className="page-content-inner">
                        <div className="row">
                          <div className="col-md-12">
                            <span id="alert_container"></span>
                            <div className="portlet light">
                              <div className="portlet-title">
                                <div className="caption">
                                  How to Add Seats to Your Service
                                </div>
                              </div>
                              <div className="portlet-body">
                                <div className="well">
                                  <p style={textStyle}>
                                    If you need to add 1 or more seats, call{' '}
                                    <span style={highlightStyle}>
                                      972-713-6622
                                    </span>{' '}
                                    between the hours of{' '}
                                    <span style={highlightStyle}>
                                      8:30 am to 5:30 pm
                                    </span>{' '}
                                    US Central Time Zone or email us at{' '}
                                    <a
                                      href="mailto:sales@evs7.com"
                                      style={highlightStyle}
                                    >
                                      sales@evs7.com
                                    </a>
                                    .
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="portlet light">
                              <div className="portlet-title">
                                <div className="caption">
                                  How to Cancel Your Service
                                </div>
                              </div>
                              <div className="portlet-body">
                                <div className="well">
                                  <p style={textStyle}>
                                    If you need to cancel 1 or more seats, call{' '}
                                    <span style={highlightStyle}>
                                      972-713-6622
                                    </span>{' '}
                                    between the hours of{' '}
                                    <span style={highlightStyle}>
                                      8:30 am to 5:30 pm
                                    </span>{' '}
                                    US Central Time Zone.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const containerStyle = {
  fontFamily: 'Arial, sans-serif',
  lineHeight: '1.6',
  padding: '20px',
  backgroundColor: '#f9f9f9',
  border: '1px solid #ddd',
  borderRadius: '8px',
  maxWidth: '600px',
  margin: '20px auto',
}

const headingStyle = {
  fontSize: '1.5rem',
  color: '#333',
  marginBottom: '10px',
}

const textStyle = {
  fontSize: '1.6rem',
  color: '#555',
  marginBottom: '15px',
}

const highlightStyle = {
  color: '#007BFF',
  fontWeight: 'bold',
}

export default MyAccount
