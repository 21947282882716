import React, { Component } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
//import Drawer_inbound from "./Standalone/Drawer_inbound";
import { notification, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import $ from 'jquery'
import '../css/responsive_menu.css'

class Header extends Component {
  styleBackground = { backgroundColor: 'white' }
  styleHeightPadding = { height: '40px', paddingTop: '5px' }
  styleNoBorder = { border: 'none' }
  styleHeightWidth = { height: '40px', width: '40px' }
  styleCallDiv = {
    display: 'inline',
    float: 'right',
    width: '150px',
    marginTop: '8px',
  }
  campaignlistings = null
  state = {
    logout_dialog_visible: false,
    campaigns: [],
    settings: {},
    user_group: false,
    onlyClientManager: false,
    imageversion: '',
    cb_due_in_1_hour: 0,
    cb_due_in_2_min: 0,
    cb_past: 0,
    user_account_active: false,
    user_role: 'agent',
  }
  constructor() {
    super()
  }
  componentDidMount() {
    console.error('REASON' + this.state.onlyClientManager)
    this.setState(
      {
        onlyClientManager: this.props.onlyClientManager,
        settings: this.props.settings,
        user_account_active: this.props.user_account_active,
        user_group: this.props.user_group,
        user_role: this.props.role,
      },
      function () {
        setTimeout(function () {
          window.Layout.init()
        }, 3000)
      }
    )
    //this.state.settings = this.props.settings;
    //lets get the settings ;)
    console.warn('Settings')
    console.warn(this.props.settings)
    this.getActiveCampaignsList()
    this.campaignlistings = setInterval(this.getActiveCampaignsList, 620000)
    //so we run this stuff here to give trial info too
    this.periodicTrialCheck()
    //////////////////////////////////////////////////////

    //periodic check for number of callbacks, will poll every minute
    this.periodicCallBackcheck()
    ////////////////////////////////////////////////////////////////////
  }
  componentWillReceiveProps(nextProps) {
    // This will erase any local state updates!
    // Do not do this.
    this.setState({
      onlyClientManager: nextProps.onlyClientManager,
      imageversion: nextProps.imageversion,
      user_account_active: this.props.user_account_active,
      settings: this.props.settings,
      user_group: this.props.user_group,
      user_role: this.props.role,
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.role != this.props.role) {
      this.setState({ user_role: this.props.role })
    }
  }
  componentWillUnmount() {
    clearInterval(this.campaignlistings)
  }
  getActiveCampaignsList = () => {
    fetch(this.props.baseUrl + '/index.php/Ajax/getActiveCampaignList', {
      credentials: 'include',
    })
      .then((blob) => blob.json())
      .then((data) => {
        var campaigns = []
        for (var i = 0; i < data.length; i++) {
          campaigns.push({
            id: data[i].campaign_id,
            name: data[i].campaign_name,
            active: data[i].active,
          })
        }
        this.setState({ campaigns: campaigns })
        console.warn(this.state.campaigns)
      })
      .catch((e) => {})
  }
  periodicCallBackcheck = () => {
    fetch(this.props.baseUrl + '/index.php/Ajax/getCallBacksDue', {
      credentials: 'include',
    })
      .then((blob) => blob.json())
      .then((data) => {
        data = data.msg
        this.setState(
          {
            cb_due_in_1_hour: data.cb_due_in_1_hour,
            cb_due_in_2_min: data.cb_due_in_2_min,
            cb_past: data.cb_past,
          },
          function () {
            if (
              data.cb_due_in_2_min !== undefined &&
              data.cb_due_in_2_min !== null &&
              data.cb_due_in_2_min !== 'null' &&
              data.cb_due_in_2_min !== ''
            ) {
              if (this.state.cb_due_in_2_min !== '0') {
                notification.open({
                  message: 'Callbacks Due Soon',
                  description:
                    this.state.cb_due_in_2_min +
                    (this.state.cb_due_in_2_min > 1
                      ? ' callbacks '
                      : ' callback ') +
                    'due in next couple of minutes',
                  duration: 60,
                  placement: 'bottomRight',
                  onClick: (e) => {
                    this.xOutCallbacksNotification()
                    this.props.history.push('/mycallbacks')
                  },
                  onClose: (e) => {
                    this.xOutCallbacksNotification()
                  },
                })
              }
            }
          }
        )
        setTimeout(this.periodicCallBackcheck, 60000)
      })
      .catch((e) => {
        setTimeout(this.periodicCallBackcheck, 60000)
      })
  }
  xOutCallbacksNotification = () => {
    fetch(this.props.baseUrl + '/index.php/Ajax/x_out_callback_notifications', {
      credentials: 'include',
    })
      .then((blob) => blob.json())
      .then((data) => {})
      .catch((e) => {})
  }
  periodicTrialCheck = () => {
    fetch(this.props.baseUrl + '/index.php/Login/give_trial_info', {
      credentials: 'include',
    })
      .then((blob) => blob.json())
      .then((data) => {
        $('#trial_info').html(data.data)
        setTimeout(this.periodicTrialCheck, 60000)
      })
      .catch((e) => {
        setTimeout(this.periodicTrialCheck, 60000)
      })
  }
  handleGlobalSearch = (f) => {
    let global_search = window.$('#global_search').val()
    window.location.href = '#/crm/leads/' + global_search
  }
  start_powerDialing = (campaign_id) => {
    //console.warn(campaign_id);
    //return false;
    window.pow_dialing_kickstart(campaign_id)
  }
  render() {
    let LeadUpload = (
      <li>
        <NavLink to="/addnew/importleads"> Import Leads</NavLink>
      </li>
    )
    let LeadAdd = (
      <li>
        <NavLink to="/addnew/lead"> Single Lead</NavLink>
      </li>
    )
    let ContactAdd = (
      <li className=" ">
        <NavLink to="/addnew/contact">Contact</NavLink>
      </li>
    )
    let ListAdd = (
      <li className=" ">
        <NavLink to="/addnew/list"> List</NavLink>
      </li>
    )
    let CampaignAdd = (
      <li className=" ">
        <NavLink to="/addnew/campaign"> Campaign</NavLink>
      </li>
    )
    let CompanyAdd = (
      <li className=" ">
        <NavLink to="/addnew/company">Company</NavLink>
      </li>
    )

    let LeadExportReport = (
      <li className="">
        <NavLink to="/reports/exportleads"> Data Export Report</NavLink>
      </li>
    )
    let smsDR = (
      <li className="">
        <NavLink to="/reports/smsdr"> Texting Report</NavLink>
      </li>
    )
    //applyig visibility conditions on lead export report
    if (this.state.user_group !== undefined && this.state.user_group !== null) {
      if (
        this.state.user_group.allowed_reports !== undefined &&
        this.state.user_group.allowed_reports !== null
      ) {
        if (!this.state.user_group.allowed_reports.includes('data_export')) {
          LeadExportReport = ''
        }
      }
    }
    if (this.state.user_role == 'agent') {
      if (this.state.settings.allow_lead_export !== undefined) {
        if (this.state.settings.allow_lead_export.toLowerCase() == 'no') {
          LeadExportReport = ''
        }
      }
    }
    if (this.state.user_role == 'manager') {
      if (this.state.settings.mgr_can_exports_leads_reports !== undefined) {
        if (this.state.settings.mgr_can_exports_leads_reports !== 'yes') {
          LeadExportReport = ''
        }
      }
    }
    //
    let CDR_report = (
      <React.Fragment>
        <li className="">
          <NavLink to="/reports/cdr"> CDR Report</NavLink>
        </li>
        <li className="">
          <NavLink to="/reports/cdr_new"> CDR Report - 1</NavLink>
        </li>
      </React.Fragment>
    )
    let ExtSummaryReport = (
      <li className="">
        <NavLink to="/reports/extsummary" className="nav-link ">
          Extension Summary Report
        </NavLink>
      </li>
    )
    let AdminLog = (
      <li className={this.state.user_role == 'superadmin' ? '' : 'hidden'}>
        <NavLink to="/reports/adminlog"> Admin Log</NavLink>
      </li>
    )
    let ReportView = (
      <li className="menu-dropdown classic-menu-dropdown">
        <NavLink to="/">
          <i className="icon-bar-chart"></i> Reports
          <span className="arrow"></span>
        </NavLink>
        <ul className="dropdown-menu">
          <li className=" ">
            <NavLink to="/reports/realtime"> Live Agent Report</NavLink>
          </li>
          {LeadExportReport}
          <li className=" ">
            <NavLink to="/reports/agentperformancedetail">
              Agent Achievement Report
            </NavLink>
          </li>
          <li className=" ">
            <NavLink to="/reports/outboundcalling">
              Outbound Dialing Report
            </NavLink>
          </li>
          {CDR_report}
          {smsDR}
          {ExtSummaryReport}
          {AdminLog}
        </ul>
      </li>
    )
    let powerdial = (
      <li className="menu-dropdown classic-menu-dropdown active ">
        <NavLink to="/">
          <i className="icon-call-end"></i> Power Dialer
          <span className="arrow"></span>
        </NavLink>
        <ul className="dropdown-menu pull-left">
          {this.state.campaigns.map((campaign) => {
            let camp_li = (
              <li key={campaign.id} disabled={campaign.active == 'N'}>
                <a
                  key={campaign.id}
                  onClick={() => {
                    this.start_powerDialing(campaign.id)
                  }}
                  className="nav-link"
                >
                  {campaign.name}{' '}
                  <small>&nbsp;&nbsp;&nbsp;&nbsp;{campaign.id}</small>
                </a>
              </li>
            )
            if (campaign.active !== 'N')
              if (
                this.state.user_group === false ||
                this.state.user_group == undefined
              )
                return camp_li
              else if (
                this.state.user_group.allowed_campaigns.includes(campaign.id)
              ) {
                return camp_li
              }
          })}
        </ul>
      </li>
    )
    let adminsection = (
      <li className="menu-dropdown  classic-menu-dropdown ">
        <NavLink to="/">
          <i className="fa fa-user-secret"></i> Admin
          <span className="arrow"></span>
        </NavLink>
        <ul className="dropdown-menu pull-left">
          <li>
            <NavLink to="/admin/masterdialsettings">
              {' '}
              Master Dial Settings{' '}
            </NavLink>
          </li>
          <li className="dropdown-submenu ">
            <NavLink to="/" className="nav-link nav-toggle ">
              {' '}
              Campaigns
              <span className="arrow"></span>
            </NavLink>
            <ul className="dropdown-menu">
              <li>
                <NavLink to="/admin/campaigns/view">View Campaigns</NavLink>
              </li>
              <li>
                <NavLink to="/addnew/campaign">Add a New Campaign</NavLink>
              </li>
            </ul>
          </li>
          <li className="dropdown-submenu ">
            <NavLink to="/" className="nav-link nav-toggle ">
              {' '}
              Lists
              <span className="arrow"></span>
            </NavLink>
            <ul className="dropdown-menu">
              <li>
                <NavLink to="/admin/lists/view">View Lists</NavLink>
              </li>
              <li>
                <NavLink to="/addnew/list">Add a New List</NavLink>
              </li>
              <li className=" ">
                <NavLink to="/addnew/importleads"> Import Leads</NavLink>
              </li>
              <li className=" ">
                <NavLink to="/admin/lists/DNC">DNC List</NavLink>
              </li>
            </ul>
          </li>

          <li className="dropdown-submenu ">
            <NavLink to="/" className="nav-link nav-toggle ">
              {' '}
              Users
              <span className="arrow"></span>
            </NavLink>
            <ul className="dropdown-menu">
              <li className="">
                <NavLink to="/admin/users/view"> View Users</NavLink>
              </li>
              {this.state.user_group == false ||
              this.state.user_group == undefined ? (
                <li className="">
                  <NavLink to="/admin/usergroups/view">
                    {' '}
                    View User Groups
                  </NavLink>
                </li>
              ) : (
                ''
              )}
              {/*<li className="">
                <NavLink to="/admin/users/add"> Add a New User </NavLink>
    </li>*/}
            </ul>
          </li>
          {/***************** **/}
          {/*this.state.user_group == false ||
          this.state.user_group == undefined ? (
            <li className="dropdown-submenu ">
              <NavLink to="/" className="nav-link nav-toggle ">
                {" "}
                User Groups
                <span className="arrow"></span>
              </NavLink>
              <ul className="dropdown-menu">
                <li className="">
                  <NavLink to="/admin/usergroups/view">
                    {" "}
                    View User Groups
                  </NavLink>
                </li>
                <li className="">
                  <NavLink to="/admin/usergroups/add">
                    {" "}
                    Add a New Group{" "}
                  </NavLink>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )*/}
          {/************************** */}
          <li className="dropdown-submenu">
            <NavLink to="/">
              Email
              <span className="arrow"></span>
            </NavLink>
            <ul className="dropdown-menu">
              <li className=" ">
                <NavLink to="/admin/emailtemplates/settings">Settings</NavLink>
              </li>
              <li className=" ">
                <NavLink to="/admin/emailtemplates/view">
                  View Templates
                </NavLink>
              </li>
              <li className=" ">
                <NavLink to="/admin/emailtemplates/add">
                  Add New Templates
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="dropdown-submenu ">
            <NavLink to="/" className="nav-link nav-toggle ">
              {' '}
              Scripts
              <span className="arrow"></span>
            </NavLink>
            <ul className="dropdown-menu">
              <li className="">
                <NavLink to="/admin/scripts/view"> View Scripts </NavLink>
              </li>
              <li className=" ">
                <NavLink to="/admin/scripts/add"> Add a New Script </NavLink>
              </li>
            </ul>
          </li>

          {/*<li>
            <NavLink to="/admin/syssettings">Settings </NavLink>
          </li>

          <li>
            <NavLink to="/admin/customfields">Custom Fields </NavLink>
          </li>
          <li>
            <NavLink to="/admin/dispoworkflows/view"> WorkFlows </NavLink>
          </li>

          <li className="dropdown-submenu">
            <NavLink to="/">
              Texting
              <span className="arrow"></span>
            </NavLink>
            <ul className="dropdown-menu">
              <li className=" ">
                <NavLink to="/admin/texttemplates/view">View Templates</NavLink>
              </li>
              <li className=" ">
                <NavLink to="/admin/texttemplates/add">
                  Add New Template
                </NavLink>
              </li>
              <li className=" ">
                <NavLink to="/admin/texttemplates/settings">Settings</NavLink>
              </li>
            </ul>
          </li>
          */}
          <li className="dropdown-submenu ">
            <NavLink to="/" className="nav-link nav-toggle ">
              <span className="arrow"></span> Voicemail Drops
            </NavLink>
            <ul className="dropdown-menu">
              <li className="">
                <NavLink to="/admin/recordings/view">View Audio Files</NavLink>
              </li>
              <li className="">
                <NavLink to="/admin/recordings/add">
                  Add a New Audio File
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/admin/configurations">Configurations </NavLink>
          </li>
          <li>
            <NavLink to="/admin/inbound/did_list">Inbound Routes</NavLink>
          </li>
        </ul>
      </li>
    )
    if (!this.props.account_active || !this.state.user_account_active) {
      powerdial = (
        <li className="menu-dropdown classic-menu-dropdown disabled ">
          <NavLink to="/">
            <i className="icon-call-end"></i> Power Dialer
            <span className="arrow"></span>
          </NavLink>
          <ul className="dropdown-menu pull-left"></ul>
        </li>
      )
    }
    if (this.state.user_role === 'agent') {
      let agent_LeadUpload = <React.Fragment></React.Fragment>
      let agent_LeadAdd = <React.Fragment></React.Fragment>
      let agent_LeadExportReport = <React.Fragment></React.Fragment>
      let agent_ReportView = <React.Fragment></React.Fragment>
      let agent_ContactAdd = <React.Fragment></React.Fragment>
      if (this.state.settings.allow_lead_upload == undefined) {
        LeadUpload = agent_LeadUpload
      } else if (this.state.settings.allow_lead_upload.toLowerCase() === 'no') {
        LeadUpload = agent_LeadUpload
      }

      if (this.state.settings.allow_lead_add == undefined) {
        LeadAdd = agent_LeadAdd
        ContactAdd = agent_ContactAdd
      } else if (this.state.settings.allow_lead_add.toLowerCase() === 'no') {
        LeadAdd = agent_LeadUpload
        ContactAdd = agent_ContactAdd
      }

      /*if (this.state.settings.allow_lead_export == undefined) {
        LeadExportReport = agent_LeadExportReport;
      } else if (this.state.settings.allow_lead_export.toLowerCase() === "no") {
        LeadExportReport = agent_LeadExportReport;
      }*/

      if (this.state.settings.allow_report_view == undefined) {
        ReportView = agent_ReportView
      } else if (this.state.settings.allow_report_view.toLowerCase() === 'no') {
        ReportView = agent_ReportView
      }

      ListAdd = <React.Fragment></React.Fragment>

      CompanyAdd = <React.Fragment></React.Fragment>
      ListAdd = <React.Fragment></React.Fragment>
      CampaignAdd = <React.Fragment></React.Fragment>

      adminsection = <React.Fragment></React.Fragment>
      CDR_report = <React.Fragment></React.Fragment>
      ExtSummaryReport = <React.Fragment></React.Fragment>
    }
    //user groups stuff here for reports
    if (
      this.state.user_group !== false &&
      this.state.user_group !== undefined
    ) {
      console.log('HEYYYY')
      ReportView = (
        <li className="menu-dropdown classic-menu-dropdown">
          <NavLink to="/">
            <i className="icon-bar-chart"></i> Reports
            <span className="arrow"></span>
          </NavLink>
          <ul className="dropdown-menu">
            {this.state.user_group.allowed_reports.includes('live_agent') ? (
              <li className=" ">
                <NavLink to="/reports/realtime"> Live Agent Report</NavLink>
              </li>
            ) : (
              ''
            )}
            {this.state.user_group.allowed_reports.includes('data_export')
              ? LeadExportReport
              : ''}
            {this.state.user_group.allowed_reports.includes(
              'agent_achievement'
            ) ? (
              <li className=" ">
                <NavLink to="/reports/agentperformancedetail">
                  Agent Achievement Report
                </NavLink>
              </li>
            ) : (
              ''
            )}
            {this.state.user_group.allowed_reports.includes(
              'outbound_dialing'
            ) ? (
              <li className=" ">
                <NavLink to="/reports/outboundcalling">
                  Outbound Dialing Report
                </NavLink>
              </li>
            ) : (
              ''
            )}
            {this.state.user_group.allowed_reports.includes('cdr')
              ? CDR_report
              : ''}
            {this.state.user_group.allowed_reports.includes('cdr') ? smsDR : ''}
            {this.state.user_group.allowed_reports.includes('ext_summary')
              ? ExtSummaryReport
              : ''}
            {this.state.user_group.allowed_reports.includes('admin_log')
              ? AdminLog
              : ''}
          </ul>
        </li>
      )
    } else {
      //console.log("BRRR");
    }
    ////////////////////////////////////////
    let client_and_pbx_section = (
      <li
        className="dropdown dropdown-extended dropdown-tasks dropdown-dark"
        id="header_task_bar"
      >
        <NavLink
          to="/"
          className="dropdown-toggle"
          data-toggle="dropdown"
          data-hover="dropdown"
          data-close-others="true"
        >
          <i className="fa fa-server font-white"></i>
        </NavLink>
        <ul className="dropdown-menu extended tasks ">
          <li>
            <NavLink to="/dolphinclients/view" className="font-white">
              Manage Clients
            </NavLink>
          </li>
          <li>
            <NavLink className="font-white" to="/pbxservers/view">
              Manage PBX
            </NavLink>
          </li>
        </ul>
      </li>
    )
    if (this.state.user_role != 'superadmin') {
      client_and_pbx_section = <React.Fragment></React.Fragment>
    }
    return (
      <div className="page-header">
        <a href="javascript:;" className="menu-toggler"></a>
        <span id="trial_info"></span>
        <div className="page-header-menu">
          <div style={{ zIndex: 10000 }} className="container">
            <div className="page-logo">
              {/*<a
                href="#/"
                onClick={(e) => {
                  window.refresh_msg = false;
                  window.location.href = this.props.baseUrl;
                }}}
              >*/}
              <a
                href={
                  this.state.onlyClientManager ? '#/dolphinclients/view' : '#/'
                }
              >
                <img
                  src="./assets/img/dolphin.png"
                  alt="logo"
                  className="logo-default"
                  style={{
                    width: '62px',
                    marginLeft: '18px',
                    paddingTop: '5px',
                  }}
                />
              </a>
            </div>

            <div className="hor-menu  ">
              <ul
                className={
                  this.state.onlyClientManager == true
                    ? 'hidden'
                    : 'nav navbar-nav '
                }
              >
                {powerdial}
                {(this.state.settings?.hide_crm_for_agents == '0' ||
                  this.state.user_role !== 'agent') && (
                  <li className="menu-dropdown classic-menu-dropdown ">
                    <NavLink to="/">
                      <i className="fa fa-book"></i> CRM
                      <span className="arrow"></span>
                    </NavLink>
                    <ul className="dropdown-menu pull-left">
                      <li>
                        {this.state.user_role == 'agent' ? (
                          this.state.settings !== undefined ? (
                            this.state.settings.limit_to_assigned_leads !==
                            undefined ? (
                              this.state.settings.limit_to_assigned_leads.toLowerCase() ==
                                'no' ||
                              this.state.settings.limit_to_assigned_leads.toLowerCase() ==
                                'x' ? (
                                <NavLink to="/crm/leads">Leads</NavLink>
                              ) : (
                                <NavLink to="/crm/myleads">Leads</NavLink>
                              )
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )
                        ) : (
                          <NavLink to="/crm/leads">Leads</NavLink>
                        )}
                      </li>
                      <li>
                        <NavLink to="/crm/contacts"> Contacts</NavLink>
                      </li>
                      {/*<li>
                      <NavLink to="/crm/companies">Companies</NavLink>
                    </li>*/}
                      <li>
                        <NavLink to="/crm/mycontacts">My Contacts</NavLink>
                      </li>
                      {(this.state.settings?.hide_crm_for_agents == '0' ||
                        this.state.user_role !== 'agent') && (
                        <li>
                          <NavLink to="/crm/myleads">My Leads</NavLink>
                        </li>
                      )}
                      <li>
                        <NavLink to="/crm/calendar"> Calendar </NavLink>
                      </li>
                    </ul>
                  </li>
                )}
                <li className="menu-dropdown classic-menu-dropdown ">
                  <NavLink to="/">
                    <i className="fa fa-plus"></i> Add New
                    <span className="arrow"></span>
                  </NavLink>
                  <ul className="dropdown-menu pull-left">
                    {LeadUpload}
                    {LeadAdd}
                    {ListAdd}
                    {CampaignAdd}
                    {ContactAdd}
                    {/*CompanyAdd*/}
                  </ul>
                </li>
                {ReportView}
                {adminsection}
                {/*****Test links section 
                {this.props.role === "superadmin" ? (
                  <li className="menu-dropdown classic-menu-dropdown ">
                    <NavLink to="/">
                      <i className="fa fa-user-secret"></i> Test
                      <span className="arrow"></span>
                    </NavLink>
                    <ul className="dropdown-menu pull-left">
                      <li>
                        <NavLink to="/test/leadslist"> Leads</NavLink>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}
                 */}
              </ul>
            </div>

            <div className="top-menu">
              <ul
                className="nav navbar-nav pull-right"
                style={{ paddingTop: '4px' }}
              >
                {/**Our outbound button */}
                {/*<li>
                  <Drawer_inbound />
                </li>*/}

                <li
                  className={
                    this.state.onlyClientManager
                      ? 'hidden'
                      : 'dropdown dropdown-extended dropdown-notification dropdown-dark '
                  }
                  id="header_notification_bar"
                >
                  <NavLink
                    to="#"
                    className={
                      this.state.onlyClientManager == true
                        ? 'dropdown-toggle hidden'
                        : 'dropdown-toggle'
                    }
                    data-toggle="dropdown"
                    data-hover="dropdown"
                    data-close-others="true"
                    style={{ paddingBottom: '8px' }}
                    title="Outbound"
                  >
                    <span class="material-icons" style={{ color: 'white' }}>
                      phone_forwarded
                    </span>
                    <span className="badge badge-default"></span>
                  </NavLink>
                  <ul
                    className="dropdown-menu"
                    style={{ backgroundColor: 'white' }}
                  >
                    <li>
                      <div className="input-group" id="wp_CallDiv">
                        <input
                          type="text"
                          className="form-control"
                          id="keypad_sel"
                          disabled={!this.state.user_account_active}
                        />
                        <span
                          className="input-group-btn"
                          id="call_ingrp_button"
                        >
                          <button
                            className="btn green-jungle"
                            id="wp_newCall"
                            type="button"
                            disabled={!this.state.user_account_active}
                          >
                            <i className="fa fa-phone"></i>
                          </button>
                        </span>
                      </div>
                    </li>
                  </ul>
                </li>
                {/***** */}
                <li
                  className={
                    this.state.onlyClientManager
                      ? 'hidden'
                      : 'dropdown dropdown-extended dropdown-notification dropdown-dark '
                  }
                  id="header_notification_bar"
                >
                  <NavLink
                    to="/"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    data-hover="dropdown"
                    data-close-others="true"
                  >
                    <i className="fa fa-search font-white"></i>
                    <span className="badge badge-default"></span>
                  </NavLink>
                  <ul className="dropdown-menu">
                    <li>
                      <div className="input-group" style={this.styleBackground}>
                        <input
                          type="text"
                          name="global_search"
                          id="global_search"
                          className="form-control"
                          style={this.styleNoBorder}
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn dark"
                            type="button"
                            onClick={this.handleGlobalSearch}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li
                  className={
                    this.state.onlyClientManager == true
                      ? 'hidden'
                      : 'dropdown dropdown-extended dropdown-tasks dropdown-dark '
                  }
                  id="header_task_bar"
                >
                  <NavLink
                    to="/mycallbacks"
                    title="My Call Backs"
                    className="dropdown-toggle"
                  >
                    <i className="icon-calendar font-white"></i>
                    {this.state.cb_due_in_1_hour > 0 ? (
                      <span
                        className="badge badge-warning"
                        title="Callbacks due within an hour."
                        style={{ color: '#F1C40F' }}
                      >
                        {this.state.cb_due_in_1_hour}
                      </span>
                    ) : this.state.cb_past > 0 ? (
                      <span
                        className="badge badge-danger"
                        title="Callbacks currently overdue."
                        style={{ color: '#ed6b75' }}
                      >
                        {this.state.cb_past}
                      </span>
                    ) : (
                      ''
                    )}
                  </NavLink>
                  <ul className="dropdown-menu extended tasks"></ul>
                </li>

                {client_and_pbx_section}
                <li className="droddown dropdown-separator">
                  <span className="separator"></span>
                </li>

                <li className="dropdown dropdown-user dropdown-dark">
                  <NavLink
                    to="/"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    data-hover="dropdown"
                    data-close-others="true"
                    style={this.styleHeightPadding}
                  >
                    <img
                      alt=""
                      style={this.styleHeightWidth}
                      className="img-circle"
                      src={
                        this.props.baseUrl +
                        '/index.php/User/get_users_avatar?' +
                        this.state.imageversion
                      }
                    />
                    &nbsp;
                    <span className="username username-hide-mobile  font-white">
                      {this.props.username}
                    </span>
                  </NavLink>
                  <ul className="dropdown-menu dropdown-menu-default">
                    <li>
                      <a
                        className="font-white"
                        target="_blank"
                        href="https://www.evs7.com/dolphin-cloud-knowledge-base"
                      >
                        <i className="fa fa-book font-white"></i> Knowledge Base{' '}
                      </a>
                    </li>
                    <li>
                      <NavLink to="/myprofile" className="font-white">
                        <i className="icon-user font-white"></i> My Profile{' '}
                      </NavLink>
                    </li>

                    {(this.state.user_role == 'owner' ||
                      this.state.user_role == 'manager') && (
                      <li>
                        <NavLink to="/myaccount" className="font-white">
                          <i className="icon-users font-white"></i> My Account
                        </NavLink>
                      </li>
                    )}

                    <li>
                      <NavLink to="/myemailconfig" className="font-white">
                        <i className="fa fa-envelope font-white"></i> My Email{' '}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/mypam"
                        className={
                          this.state.onlyClientManager == true
                            ? 'hidden'
                            : 'font-white '
                        }
                      >
                        <i className="fa fa-file-audio-o font-white"></i> My PAM{' '}
                      </NavLink>
                    </li>
                    {(this.state.settings?.hide_crm_for_agents == '0' ||
                      this.state.user_role !== 'agent') && (
                      <li>
                        <NavLink
                          to="/crm/myleads"
                          className={
                            this.state.onlyClientManager == true
                              ? 'hidden'
                              : 'font-white'
                          }
                        >
                          <i className="fa fa-leaf font-white"></i> My Leads{' '}
                        </NavLink>
                      </li>
                    )}
                    <li>
                      <NavLink
                        to="/mycallbacks"
                        className={
                          this.state.onlyClientManager == true
                            ? 'hidden'
                            : 'font-white'
                        }
                      >
                        <i className="fa fa-phone font-white"></i> My Call Backs{' '}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/mystats"
                        className={
                          this.state.onlyClientManager == true
                            ? 'hidden'
                            : 'font-white'
                        }
                      >
                        <i className="fa fa-sticky-note-o font-white"></i> My
                        Stats{' '}
                      </NavLink>
                    </li>
                    <li>
                      <a
                        onClick={(e) => {
                          this.setState({ logout_dialog_visible: true })
                        }}
                        className="font-white"
                      >
                        <i className="icon-key font-white"></i> Log Out{' '}
                      </a>
                      <Modal
                        closable={false}
                        title="Are you Sure"
                        visible={this.state.logout_dialog_visible}
                        cancelButtonProps={{ danger: true }}
                        onCancel={this.props.Logout}
                        onOk={() => {
                          this.setState({ logout_dialog_visible: false })
                        }}
                        okText="No"
                        cancelText="Yes"
                      >
                        <p>Are you sure you want to logout?</p>
                      </Modal>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            {/*<div
              className={this.state.onlyClientManager == true ? "hidden" : ""}
              style={this.styleCallDiv}
            >
              <div className="input-group" id="wp_CallDiv">
                <input
                  type="text"
                  className="form-control"
                  id="keypad_sel"
                  disabled={!this.state.user_account_active}
                />
                <span className="input-group-btn" id="call_ingrp_button">
                  <button
                    className="btn green-jungle"
                    id="wp_newCall"
                    type="button"
                    disabled={!this.state.user_account_active}
                  >
                    <i className="fa fa-phone"></i>
                  </button>
                </span>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    )
  }
}
export default Header
