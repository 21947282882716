import React, { Component } from 'react'
import $ from 'jquery'
import { NavLink } from 'react-router-dom'
import { Popconfirm, Collapse, message, Radio } from 'antd'
import 'antd/dist/antd.css'
import '../../css/DynamicCIDCampaignWidget.css'

class WhitelistedCids extends React.Component {
  state = {
    client_id: '',
    client_fullname: '',
    client_url: '',
    edit_list: [],
    toggle_checked: 0,
    add_cid_dynamic_type: 'onebyone',
  }
  componentDidMount() {
    window.resizeHandle()
    this.initClient()
  }
  initClient = () => {
    var cid = this.props.match.params.client_id
    this.setState({ client_id: cid })
    ////////////////////////////////////
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/SuperAdmin/ClientEdit/' + cid, {
      method: 'GET',
      credentials: 'include',
    })
      .then((blob) => blob.json())
      .then((data) => {
        console.log(data)
        data = data.msg
        console.error(data)
        this.setState(
          {
            client_id: data.id,
            client_fullname: data.fullname,
            client_url: data.url,
          },
          () => {
            this.doTheDynamicWidget()
          }
        )
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  /***
   *
   */

  dt_dynamic_cids = null

  doTheDynamicWidget = () => {
    if (this.dt_dynamic_cids == null) {
      window.oooTable = this.dt_dynamic_cids = window.dttable(
        'dt_dynamic_cids',
        `${this.props.baseUrl}/index.php/SuperAdmin/WhitelistedCID/${this.state.client_id}/list_all`,
        ''
      )
      /****Below is delete control for Dynamic CIDs */
      var Aurl = this.props.baseUrl
      var dtable_dynamic_cids = this.dt_dynamic_cids

      var blockUI = this.props.blockUI
      $('#dt_dynamic_cids').on('click', '.btn-danger', function () {
        var childKey = $(this).attr('delete_id')
        blockUI(true)
        fetch(
          Aurl +
            `/index.php/SuperAdmin/WhitelistedCID/${this.state.client_id}/delete/${childKey}`,
          {
            method: 'GET',
            credentials: 'include',
          }
        )
          .then((blob) => {
            return blob.json()
          })
          .then((data) => {
            console.log(data)
            if (data.status) {
              window.displayAlert('#alert_container', data.msg, 'success')
            } else {
              window.displayAlert('#alert_container', data.msg, 'danger')
            }
            dtable_dynamic_cids.api().ajax.reload()
            blockUI(false)
          })
          .catch((e) => {
            blockUI(false)
          })
      })
      try {
        $('#dt_dynamic_cids').on('change', '.cid_toggler', async (e) => {
          this.props.blockUI(true)
          try {
            var childKey = $(e.target).attr('cid_id')
            const add = $(e.target).is(':checked') ? 1 : 0
            const res = await fetch(
              `${this.props.baseUrl}/index.php/SuperAdmin/WhitelistedCID/${this.state.client_id}/toggle_whitelisted_cid/${childKey}`,
              {
                credentials: 'include',
                method: 'post',
                body: new URLSearchParams({
                  cid_id: childKey,
                  action: 'toggle_dynamic_cid',
                  toggle_to: add,
                }), //new URLSearchParams($("#form_add_dynamic_cid").serialize()),
              }
            )
            const res_j = await res.json()
            if (res_j.status) {
              window.displayAlert('#alert_container', res_j.msg, 'success')
            } else {
              window.displayAlert('#alert_container', res_j.msg, 'danger')
            }
          } catch (e) {
          } finally {
            this.props.blockUI(false)
          }
          // console.log($(e.target), ' ', childKey, ' ', add, ' ', e.target)
        })
      } catch (e) {}
      //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
      dtable_dynamic_cids.api().column(2).visible(!this.state.follow_master)
    } else {
      //table.ajax.url( 'newData.json' ).load();
      this.dt_dynamic_cids
        .api()
        .ajax.url(
          `${this.props.baseUrl}/index.php/SuperAdmin/WhitelistedCID/${this.state.client_id}/list_all`
        )
        .load()
      this.dt_dynamic_cids.api().column(2).visible(!this.state.follow_master)
    }
  }
  /*************************Adding dynamic cid code */
  handleadddynamiccid = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(
      `${this.props.baseUrl}/index.php/SuperAdmin/WhitelistedCID/${this.state.client_id}/add_whitelisted_cid`,
      {
        credentials: 'include',
        method: 'post',
        body: new FormData(e.target), //new URLSearchParams($("#form_add_dynamic_cid").serialize()),
      }
    )
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.$('#modal_add_dynamic_cid').modal('hide')
        this.props.blockUI(false)
        this.dt_dynamic_cids.api().ajax.reload()
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  onAddDCChange = (e) => {
    console.log('radio checked', e.target.value)
    this.setState({ add_cid_whitelisted_type: e.target.value })
  }
  displayEditModal = async () => {
    this.props.blockUI(true)
    try {
      await this.setState({ edit_list: [] })
      const res = await fetch(
        `${this.props.baseUrl}/index.php/SuperAdmin/WhitelistedCID/${this.state.client_id}/list_all_whitelisted_cids`,
        {
          credentials: 'include',
          method: 'get',
        }
      )
      const res_j = await res.json()
      if (res_j?.status && res_j?.data) {
        this.setState({ edit_list: res_j.data }, () => {
          window.$('#modal_edit_dynamic_cid').modal('show')
        })
      } else {
      }
    } catch (e) {
    } finally {
      this.props.blockUI(false)
    }
  }

  /***************************************************** */
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    WhiteListed Caller Ids
                    <small> {this.state.client_url} </small>
                  </h1>
                </div>
                <div className="page-toolbar  tabbable-line">
                  {/* <ul className="nav nav-tabs pull-right">
                    <li className="active">
                      <NavLink to="/#tab_settings" data-toggle="tab">
                        {' '}
                        Settings{' '}
                      </NavLink>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="alert_container"></div>
                      {/****Dynamic CID portlet */}

                      <div className="portlet portlet-sortable light bordered">
                        <div className="portlet-title">
                          <div className="caption font-green-sharp">
                            <i className="icon-speech font-green-sharp"></i>
                            <span className="caption-subject bold ">
                              {' '}
                              Whitelisted Caller IDs
                            </span>
                            <span className="caption-helper font-red">
                              {/* <br /> We highly recommend at least 5 CIDs per
                              each user. */}
                            </span>
                          </div>
                          <div className="actions">
                            <>
                              <a
                                onClick={(e) => {
                                  this.displayEditModal()
                                }}
                                className="btn btn-circle btn-default btn-sm"
                                style={{ marginRight: '4px' }}
                              >
                                <i className="fa fa-pencil"></i> Edit{' '}
                              </a>
                              <a
                                id="modal_AddDynamicCID"
                                onClick={(e) => {
                                  window
                                    .$('#modal_add_dynamic_cid')
                                    .modal('show')
                                }}
                                className="btn btn-circle btn-default btn-sm"
                              >
                                <i className="fa fa-plus"></i> Add{' '}
                              </a>
                            </>
                          </div>
                        </div>
                        <div className="portlet-body">
                          <table
                            className="table table-striped table-bordered table-advance table-hover"
                            id="dt_dynamic_cids"
                          >
                            <thead>
                              <tr>
                                <th>Area Code</th>
                                <th>Caller ID</th>
                                <th>Description</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>

                      {/***** */}
                    </div>
                    {/*<!-----------Dynamic CID Modal to Add------------------->*/}
                    <div
                      className="modal fade draggable-modal"
                      id="modal_add_dynamic_cid"
                      tabindex="-1"
                      role="basic"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            ></button>
                            <h4 className="modal-title">ADD NEW Dynamic CID</h4>
                          </div>

                          <form
                            className="form-horizontal"
                            onSubmit={this.handleadddynamiccid}
                            id="form_add_dynamic_cid"
                            method="post"
                            encType="multipart/form-data"
                          >
                            <input
                              type="hidden"
                              name="action"
                              defaultValue="add_dynamic_cid"
                            />
                            <input
                              type="hidden"
                              name="campaign_id"
                              value={this.state.campaign_id}
                            />
                            <input
                              type="hidden"
                              name="add_cid_type"
                              value={this.state.add_cid_whitelisted_type}
                            />
                            <div className="modal-body">
                              {/* Lets do this, add multiple option and add by csv */}
                              <Radio.Group
                                onChange={this.onAddDCChange}
                                value={this.state.add_cid_whitelisted_type}
                                style={{ marginBottom: '10px' }}
                              >
                                <Radio value="one_by_one">One</Radio>
                                <Radio value="multiple">Mutliple</Radio>
                                <Radio value="csv">Upload CSV</Radio>
                              </Radio.Group>
                              {/******************************* */}
                              {this.state.add_cid_whitelisted_type ==
                              'one_by_one' ? (
                                <React.Fragment>
                                  <div className="form-body">
                                    <div className="form-group">
                                      <label className="control-label col-md-3">
                                        Area code
                                      </label>
                                      <div className="col-md-4">
                                        <input
                                          type="text"
                                          className="sel2_in_modal form-control"
                                          name="areacode"
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group">
                                      <label className="control-label col-md-3">
                                        CID
                                      </label>
                                      <div className="col-md-4">
                                        <input
                                          type="text"
                                          className="sel2_in_modal form-control"
                                          name="cid"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-body">
                                    <div className="form-group">
                                      <label className="control-label col-md-3">
                                        Description
                                      </label>
                                      <div className="col-md-4">
                                        <textarea
                                          type="text"
                                          className="sel2_in_modal form-control"
                                          name="description"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                ''
                              )}
                              {this.state.add_cid_whitelisted_type ==
                              'multiple' ? (
                                <React.Fragment>
                                  <div className="form-body">
                                    <div className="form-group">
                                      <label className="control-label col-md-3">
                                        CIDs
                                      </label>
                                      <div className="col-md-4">
                                        <textarea
                                          name="cids"
                                          className="form-control"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                ''
                              )}
                              {this.state.add_cid_whitelisted_type == 'csv' ? (
                                <React.Fragment>
                                  <div className="form-body">
                                    <div className="form-group">
                                      <label className="control-label col-md-3">
                                        File
                                      </label>
                                      <div className="col-md-4">
                                        <input
                                          type="file"
                                          class="form-control-file"
                                          id="csv_file"
                                          name="csv_file"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn dark btn-outline"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="submit"
                                className="btn green-jungle"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                        {/*<!-- /.modal-content -->*/}
                      </div>
                      {/*<!-- /.modal-dialog -->*/}
                    </div>
                    {/*<!--------------------------------------------------------->*/}
                    {/*<!-----------Dynamic CID Modal to Add------------------->*/}
                    <div
                      className="modal fade draggable-modal"
                      id="modal_edit_dynamic_cid"
                      tabindex="-1"
                      role="basic"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-hidden="true"
                            ></button>
                            <h4 className="modal-title">
                              Edit Dynamic Caller IDs
                            </h4>
                          </div>
                          <div className="modal-body">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Area Code</th>
                                  <th>Caller ID</th>
                                  <th>Description</th>
                                  <th>Status</th>
                                  <th>Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.edit_list.map((ci, ind) => (
                                  <tr>
                                    <td>
                                      {/* <label className="label label-default">
                                        {ci.area_code}
                                      </label> */}
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={ci.area_code}
                                        onChange={(e) => {
                                          const all_cids = this.state.edit_list
                                          all_cids[ind].area_code =
                                            e.target.value
                                          this.setState({ edit_list: all_cids })
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={ci.cid}
                                        onChange={(e) => {
                                          const all_cids = this.state.edit_list
                                          all_cids[ind].cid = e.target.value
                                          this.setState({ edit_list: all_cids })
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <textarea
                                        className="form-control"
                                        onChange={(e) => {
                                          const all_cids = this.state.edit_list
                                          all_cids[ind].description =
                                            e.target.value
                                          this.setState({ edit_list: all_cids })
                                        }}
                                        value={ci.description}
                                      >
                                        {ci.description}
                                      </textarea>{' '}
                                    </td>
                                    <td>
                                      <label className="switch_dcid">
                                        <input
                                          onChange={(e) => {
                                            const all_cids =
                                              this.state.edit_list
                                            all_cids[ind].is_active = e.target
                                              .checked
                                              ? '1'
                                              : '0'
                                            this.setState({
                                              edit_list: all_cids,
                                            })
                                          }}
                                          className="cid_toggler"
                                          type="checkbox"
                                          checked={ci.is_active == '1'}
                                        />
                                        <div className="slider_dcid round">
                                          {/*ADDED HTML */}
                                          {ci.is_active == '1' && (
                                            <span className="on">ON</span>
                                          )}
                                          {ci.is_active == '0' && (
                                            <span className="off">OFF</span>
                                          )}
                                          {/*END*/}
                                        </div>
                                      </label>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-danger"
                                        onClick={(e) => {
                                          const all_cids = this.state.edit_list
                                          all_cids.splice(ind, 1)
                                          this.setState({ edit_list: all_cids })
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="modal-footer">
                            <button
                              className="btn btn-success"
                              onClick={async (e) => {
                                this.props.blockUI(true)
                                try {
                                  const resp = await fetch(
                                    `${this.props.baseUrl}/index.php/SuperAdmin/WhitelistedCID/${this.state.client_id}/bulk_edit_whitelisted_cids`,
                                    {
                                      method: 'POST',
                                      credentials: 'include',
                                      body: new URLSearchParams({
                                        edit_list: JSON.stringify(
                                          this.state.edit_list
                                        ),
                                      }),
                                    }
                                  )
                                  const resp_j = await resp.json()
                                  if (resp_j.status) {
                                    window.displayAlert(
                                      '#alert_container',
                                      resp_j.msg,
                                      'success'
                                    )
                                  } else {
                                    window.displayAlert(
                                      '#alert_container',
                                      resp_j.msg,
                                      'danger'
                                    )
                                  }
                                } catch (e) {
                                  console.log('error raised somehow', e)
                                  window.displayAlert(
                                    '#alert_container',
                                    'Something went wrong',
                                    'success'
                                  )
                                } finally {
                                  this.dt_dynamic_cids.api().ajax.reload()
                                  this.props.blockUI(false)
                                }
                              }}
                            >
                              Save All
                            </button>
                          </div>
                        </div>
                        {/*<!-- /.modal-content -->*/}
                      </div>
                      {/*<!-- /.modal-dialog -->*/}
                    </div>
                    {/*<!--------------------------------------------------------->*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default WhitelistedCids
